import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { FirebaseAppProvider } from "reactfire";

var firebaseConfig = {
  apiKey: "AIzaSyAxqKOSMMnC_ZWFHmQtxONm1xQRk5fGbkc",
  authDomain: "life-dashboard-3a599.firebaseapp.com",
  projectId: "life-dashboard-3a599",
  storageBucket: "life-dashboard-3a599.appspot.com",
  messagingSenderId: "191991139126",
  appId: "1:191991139126:web:b57f80acef06da2dd38197",
};

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </FirebaseAppProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
