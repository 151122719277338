import React from "react";
import "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import "./App.css";

function RunStreak() {
  const docRef = useFirestore().collection("metrics").doc("strava");

  type StravaMetrics = {
    runstreak: number;
    total_meters_run_current_year: number;
  };

  const { status, data } = useFirestoreDocData<StravaMetrics>(docRef);

  if (status === "loading") {
    return <p>Fetching activites...</p>;
  }

  return (
    <>
      <h1 className="Run-counter">Runstreak: {data.runstreak} days</h1>
      <h1>
        2021 distance goal:{" "}
        {Math.round(data.total_meters_run_current_year / 1000)}km / 2300km
      </h1>
    </>
  );
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <RunStreak />
      </header>
    </div>
  );
}

export default App;
